`Copyright 2022 Bio-Conversion Databank Foundation

Licensed under the Apache License, Version 2.0(the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License`;

import { wordArrToEnum, camelToEnum } from '@/utilities/dataPreprocess.js';
import {
  createPhredSubProject,
  createComparisonSubProject,
  createComparisonChart,
  createPhyloTreeSubProject,
} from '@/graphql/mutations.js';
import {
  listProjects,
  listPhredSubProjects,
  listPhyloTreeSubProjects,
  listComparisonSubProjects,
  listComparisonCharts,
} from '@/graphql/queries.js';
export default {
  methods: {
    updateSubprojects() {
      this.$apollo.queries.listPhredSubProjects.refetch();
      this.$apollo.queries.listPhyloTreeSubProjects.refetch();
      this.$apollo.queries.listComparisonSubProjects.refetch();
      this.$apollo.queries.listComparisonCharts.refetch();
    },
    saveasSubproject() {
      // query based on page opened
      if (this.$route.name === 'Phred') {
        this.saveasPhredSubproject();
      } else if (this.$route.name === 'Comparison') {
        this.saveasComparisonSubproject();
      } else if (this.$route.name === 'PhyloTree') {
        this.saveasPhyloTreeSubproject();
      }
    },
    saveasPhredSubproject() {
      let phred = this.$store.state.phred;
      this.$apollo
        .mutate({
          mutation: createPhredSubProject,
          variables: {
            input: {
              name: this.saveas,
              projectId: this.selectedProject,
              search: phred.search,
              filterReceptorLocation: phred.filterReceptorLocation,
              filterFamily: wordArrToEnum(phred.filterFamily),
              filterDifferentialStain: wordArrToEnum(phred.filterGramStain),
              owner: this.$store.state.user.userAttributes['cognito:username'],
              userId: this.$store.state.user.userAttributes['cognito:username'],
            },
          },
        })
        .then(({ data }) => {
          this.$emit('close-dialog');
          // dummy data
          let subproject = {
            id: data.createPhredSubProject.id,
            name: this.saveas,
            projectId: this.selectedProject,
            type: 'Phred',
            search: phred.search,
            filterReceptorLocation: phred.filterReceptorLocation,
            filterFamily: wordArrToEnum(phred.filterFamily),
            filterDifferentialStain: wordArrToEnum(phred.filterGramStain),
          };
          this.$store.dispatch('phred/setCurrentSubproject', subproject);
          this.updateSubprojects();
          const notification = {
            type: 'success',
            message: 'Subproject is successfully saved',
          };
          this.$store.dispatch('addNotification', notification);
          this.openSavedSubproject(subproject);
        })
        .catch((err) => {
          console.log(err); // DEBUG
          const notification = {
            type: 'error',
            message: 'Could not save subproject',
          };
          this.$store.dispatch('addNotification', notification);
        });
    },
    saveasComparisonSubproject() {
      let comparison = this.$store.state.comparison;
      // create the subproject
      this.$apollo
        .mutate({
          mutation: createComparisonSubProject,
          variables: {
            input: {
              name: this.saveas,
              projectId: this.selectedProject,
              owner: this.$store.state.user.userAttributes['cognito:username'],
              userId: this.$store.state.user.userAttributes['cognito:username'],
              organisms: comparison.species,
            },
          },
        })
        .then(({ data }) => {
          this.$emit('close-dialog');
          let subProjectId = data.createComparisonSubProject.id;
          let charts = [];
          const selectedProject = this.selectedProject;
          const saveas = this.saveas;
          if (comparison.diagramList.length > 0) {
            // create the charts
            comparison.diagramList.forEach((n, i) => {
              let chart = 'chart' + n.id;
              this.$apollo
                .mutate({
                  mutation: createComparisonChart,
                  variables: {
                    input: {
                      projectId: this.selectedProject,
                      subProjectId,
                      owner:
                        this.$store.state.user.userAttributes[
                          'cognito:username'
                        ],
                      userId:
                        this.$store.state.user.userAttributes[
                          'cognito:username'
                        ],
                      chartType: camelToEnum(comparison.charts[chart]),
                      parameters: comparison.properties[chart],
                    },
                  },
                })
                .then(({ data }) => {
                  // create dummy data for chart
                  charts.push({
                    chartType: data.createComparisonChart.chartType,
                    parameters: data.createComparisonChart.parameters,
                    id: data.createComparisonChart.id,
                  });
                  // process all of these only in the last iteration
                  if (i == comparison.diagramList.length - 1) {
                    // saving dummy data
                    let subproject = {
                      projectId: selectedProject,
                      id: subProjectId,
                      name: saveas,
                      type: 'Comparison',
                      organisms: comparison.species,
                      charts,
                    };
                    this.$store.dispatch(
                      'comparison/setCurrentSubproject',
                      subproject,
                    );
                    this.updateSubprojects();
                    const notification = {
                      type: 'success',
                      message: 'Subproject is successfully saved',
                    };
                    this.$store.dispatch('addNotification', notification);
                    this.openSavedSubproject(subproject);
                  }
                })
                .catch((err) => {
                  console.log(err); // DEBUG
                  const notification = {
                    type: 'error',
                    message: 'Could not save subproject',
                  };
                  this.$store.dispatch('addNotification', notification);
                });
            });
          } else {
            // saving dummy data
            let subproject = {
              projectId: this.selectedProject,
              id: subProjectId,
              name: this.saveas,
              type: 'Comparison',
              organisms: comparison.species,
              charts,
            };
            this.$store.dispatch('comparison/setCurrentSubproject', subproject);
            this.updateSubprojects();
            const notification = {
              type: 'success',
              message: 'Subproject is successfully saved',
            };
            this.$store.dispatch('addNotification', notification);
            this.openSavedSubproject(subproject);
          }
        })
        .catch((err) => {
          console.log(err); // DEBUG
          const notification = {
            type: 'error',
            message: 'Could not save subproject',
          };
          this.$store.dispatch('addNotification', notification);
        });
    },
    saveasPhyloTreeSubproject() {
      let phylotree = this.$store.state.phylotree;
      let inputGroup = phylotree.bacterialGroup;
      if (!inputGroup) {
        inputGroup = 'Methanotroph (Gammaproteobacteria)';
      }
      let subproject = {
        name: this.saveas,
        projectId: this.selectedProject,
        alignLabels: phylotree.alignLabels ? 'YES' : 'NO',
        bacterialGroup: inputGroup,
        branchLengths: phylotree.branchLengths ? 'YES' : 'NO',
        circular: phylotree.circular ? 'YES' : 'NO',
        displayBackgroundColor: phylotree.displayBackgroundColor ? 'YES' : 'NO',
        displayClostridiumPathogens: phylotree.displayClostridiumPathogens
          ? 'YES'
          : 'NO',
        displayGenomeLength: phylotree.displayGenomeLength ? 'YES' : 'NO',
        displayInnerNodes: phylotree.displayInnerNodes ? 'YES' : 'NO',
        displayLeafLabels: phylotree.displayLeafLabels ? 'YES' : 'NO',
        displayLeaves: phylotree.displayLeaves ? 'YES' : 'NO',
        displayLegend: phylotree.displayLegend ? 'YES' : 'NO',
        showMethanoGenus: phylotree.displayMethanoGenus ? 'YES' : 'NO',
        showOutgroup: phylotree.displayOutgroup ? 'YES' : 'NO',
        rings: phylotree.rings,
        displayOrder: phylotree.displayOrder,
        owner: this.$store.state.user.userAttributes['cognito:username'],
        userId: this.$store.state.user.userAttributes['cognito:username'],
      };

      this.$apollo
        .mutate({
          mutation: createPhyloTreeSubProject,
          variables: {
            input: subproject,
          },
        })
        .then(({ data }) => {
          this.$emit('close-dialog');
          subproject['type'] = 'PhyloTree';
          subproject.id = data.createPhyloTreeSubProject.id;
          this.$store.dispatch('setCurrentSubproject', subproject);
          this.updateSubprojects();
          const notification = {
            type: 'success',
            message: 'Project is successfully created',
          };
          this.$store.dispatch('addNotification', notification);
          this.openSavedSubproject(subproject);
        })
        .catch((err) => {
          console.log(err); // DEBUG
          const notification = {
            type: 'error',
            message: 'Could not save subproject',
          };
          this.$store.dispatch('addNotification', notification);
        });
    },
    //Open project and direct user to the page when they create a project
    openSavedSubproject(subproject) {
      this.$store.commit('project/SET_ACTIVE_SUBPROJECT', subproject);
      this.$store.commit('project/SET_IS_EDITED', false);
      if (this.$route.name !== subproject.type) {
        // change page if it's different
        if (subproject.type == 'PhyloTree') {
          this.$router.push({
            name: 'PhyloTree',
            params: { organism: subproject.bacterialGroup },
          });
        } else {
          this.$router.push({ name: subproject.type });
        }
      }
      this.resetForm();
    },
  },
  apollo: {
    listProjects: {
      query: listProjects,
      error(err) {
        console.log(err);
      },
    },
    listPhredSubProjects: {
      query: listPhredSubProjects,
      error(err) {
        console.log(err);
      },
    },
    listPhyloTreeSubProjects: {
      query: listPhyloTreeSubProjects,
      error(err) {
        console.log(err);
      },
    },
    listComparisonSubProjects: {
      query: listComparisonSubProjects,
      error(err) {
        console.log(err);
      },
    },
    listComparisonCharts: {
      query: listComparisonCharts,
      error(err) {
        console.log(err);
      },
    },
  },
};
