var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{attrs:{"heading":"Add A New SubProject"},on:{"close-dialog":function () {
      _vm.$emit('close-dialog');
      _vm.resetForm();
    }}},[_c('v-col',{attrs:{"cols":"auto"}},[(_vm.isSaveAs)?_c('v-menu',{attrs:{"open-on-hover":"","nudge-bottom":"0","allow-overflow":"","max-height":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-5",attrs:{"outlined":"","width":"500"}},on),[_vm._v(" "+_vm._s(_vm.selectedProjectObject ? ("Selected Project: " + (_vm.selectedProjectObject.projectName)) : 'Select Project')+" ")])]}}],null,false,1861843504)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.showCreateProject = true}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Create a New Project ")],1)],1)],1),_c('v-divider',{staticClass:"mt-2"}),_vm._l((_vm.projects),function(item,index){return _c('v-list-item',{key:index,on:{"click":function () {
              _vm.selectedProjectObject = item;
            }}},[_c('v-list-item-title',[_vm._v(_vm._s(item.projectName))])],1)})],2)],1):_vm._e(),(!_vm.isSaveAs)?_c('v-select',{ref:"selectSubprojectType",staticClass:"mt-3",attrs:{"items":_vm.items,"label":"Select your new SubProject type","dense":"","outlined":"","height":"40","item-name":"name","item-value":"name","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var item = ref.item;
return [(item.icon)?_c('v-icon',{attrs:{"color":"primary","height":"30","width":"30"}},[_vm._v(_vm._s(item.icon))]):_vm._e(),(item.image)?_c('v-img',{attrs:{"src":item.image,"max-height":"30","max-width":"30"}}):_vm._e(),_c('span',{staticClass:"ml-4"},[_vm._v(_vm._s(item.name)+" ")])]}},{key:"item",fn:function(ref){
            var item = ref.item;
return [(item.icon)?_c('v-icon',{attrs:{"height":"30","width":"30","color":"primary"}},[_vm._v(_vm._s(item.icon))]):_vm._e(),(item.image)?_c('v-img',{attrs:{"src":item.image,"max-height":"30","max-width":"30"}}):_vm._e(),_c('span',{staticClass:"ml-4"},[_vm._v(_vm._s(item.name)+" ")])]}}],null,false,2965062236),model:{value:(_vm.selectedSubprojectType),callback:function ($$v) {_vm.selectedSubprojectType=$$v},expression:"selectedSubprojectType"}}):_vm._e(),_c('v-form',{ref:"textSubmit",staticClass:"ml-4 mt-4"},[_c('v-text-field',{staticClass:"select",attrs:{"label":"New SubProject Name","outlined":"","height":"40","dense":"","clearable":"","rules":_vm.saveasRules},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.isSaveasValid && _vm.saveSubproject()}},model:{value:(_vm.saveas),callback:function ($$v) {_vm.saveas=$$v},expression:"saveas"}}),_c('v-btn',{staticClass:"mx-1 mt-3",attrs:{"color":"error"},on:{"click":function($event){return _vm.$emit('close-dialog')}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"ml-4 mt-3",attrs:{"color":"primary","disabled":!_vm.isFormValid},on:{"click":_vm.saveSubproject}},[_vm._v(" Create ")])],1)],1),_c('v-divider'),(_vm.showCreateProject)?_c('div',[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"sm":"11"}},[_c('v-text-field',{staticClass:"mt-10",attrs:{"label":"Enter New Project Name","outlined":"","clearable":"","dense":""},model:{value:(_vm.newprojectName),callback:function ($$v) {_vm.newprojectName=$$v},expression:"newprojectName"}})],1),_c('v-spacer')],1),_c('v-row',{staticClass:"mt-n10"},[_c('v-spacer'),_c('v-btn',{staticClass:"mt-10 mb-10",attrs:{"disabled":_vm.newprojectName.length == 0,"color":"primary"},on:{"click":_vm.createProject}},[_vm._v(" Create ")]),_c('v-spacer')],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }