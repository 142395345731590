`Copyright 2022 Bio-Conversion Databank Foundation

Licensed under the Apache License, Version 2.0(the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License`;

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import store from './store';
import VueTour from 'vue-tour';
import VueGtag from 'vue-gtag';
import VueMeta from 'vue-meta';

require('@/utilities/vue-tour.css');

import { createProvider } from './vue-apollo/vue-apollo';

Vue.config.productionTip = false;
Vue.config.silent = true;
Vue.use(VueTour);
Vue.use(VueMeta);
Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID },
    // we want analytics to be disabled intially until we get the user's preferences
    enabled: false,
  },
  router,
);

new Vue({
  router,
  vuetify,
  store,
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount('#app');
