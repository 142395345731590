Copyright 2022 Bio-Conversion Databank Foundation Licensed under the Apache
License, Version 2.0 (the "License"); you may not use this file except in
compliance with the License. You may obtain a copy of the License at
http://www.apache.org/licenses/LICENSE-2.0 Unless required by applicable law or
agreed to in writing, software distributed under the License is distributed on
an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express
or implied. See the License for the specific language governing permissions and
limitations under the License

<template>
  <div>
    <p class="mt-2 mx-2">
      Are you sure you want to delete "{{ item.name }}"? This action is
      irreversible. Please click delete to continue.
    </p>
    <v-btn
      color="primary"
      outlined
      class="mx-1"
      @click="$emit('close-expanded')"
    >
      Cancel
    </v-btn>
    <v-btn
      color="error"
      class="mx-1"
      @click="submitDelete(item.id, subprojectMetadata[item.__typename].name)"
    >
      Delete
    </v-btn>
  </div>
</template>

<script>
import {
  deletePhredSubProject,
  deletePhyloTreeSubProject,
  deleteComparisonSubProject,
  deleteComparisonChart,
} from '@/graphql/mutations.js';
import { getProject } from '@/graphql/queries.js';
import { subprojectClose } from '@/mixins/project';

export default {
  name: 'SubprojectDelete',
  mixins: [subprojectClose],
  props: {
    item: Object,
    subprojectMetadata: Object,
    listComparisonCharts: Array,
    currentSubproject: Object,
  },
  methods: {
    deleteQuery(type) {
      if (type === 'Phred') {
        return deletePhredSubProject;
      } else if (type === 'PhyloTree') {
        return deletePhyloTreeSubProject;
      } else if (type === 'Comparison') {
        return deleteComparisonSubProject;
      }
    },
    submitDelete(id, type) {
      if (type === 'Comparison') {
        this.deleteCharts(id, type);
      } else {
        this.deleteSubproject(id, type);
      }
    },
    async deleteSubproject(id, type) {
      /*
      const result = await this.$apollo.query({
        query: getProject,
        variables: {
          id: this.selectedProject,
        },
      });
      const usersDeleteAccess = result.data.getProject.usersDeleteAccess;
      const owner = result.data.getProject.owner;
      if (
        owner != this.$store.state.user.userAttributes['cognito:username'] &&
        usersDeleteAccess == false
      ) {
        const notification = {
          type: 'error',
          message: 'User does not have permission to perform this task',
        };
        this.$store.dispatch('addNotification', notification);
        return;
      }
      */
      this.$apollo
        .mutate({
          mutation: this.deleteQuery(type),
          variables: {
            input: {
              id,
            },
          },
        })
        .then((data) => {
          this.afterDelete(id);
        })
        .catch((err) => {
          console.log(err); // DEBUG
          const notification = {
            type: 'error',
            message: 'Could not delete subproject',
          };
          this.$store.dispatch('addNotification', notification);
        });
    },
    deleteCharts(id, type) {
      let charts = this.listComparisonCharts.filter(
        (item) => item.subProjectId === id,
      );
      if (charts.length > 0) {
        charts.forEach((item, i) => {
          this.$apollo
            .mutate({
              mutation: deleteComparisonChart,
              variables: {
                input: {
                  id: item.id,
                },
              },
            })
            .then((data) => {
              // process only on last iteration
              if (i === charts.length - 1) {
                this.deleteSubproject(id, type);
              }
            })
            .catch((err) => {
              console.log(err); // DEBUG
              const notification = {
                type: 'error',
                message: 'Could not delete subproject',
              };
              this.$store.dispatch('addNotification', notification);
            });
        });
      } else {
        this.deleteSubproject(id, type);
      }
    },
    afterDelete(id) {
      if (!!this.currentSubproject && id === this.currentSubproject.id) {
        // close if deleted subproject is opened
        this.closeSubproject();
      }
      this.$emit('update-subprojects');
      this.$emit('reset-selected');
      const notification = {
        type: 'success',
        message: 'Subproject is successfully deleted',
      };
      this.$store.dispatch('addNotification', notification);
    },
  },
};
</script>
