Copyright 2022 Bio-Conversion Databank Foundation Licensed under the Apache
License, Version 2.0 (the "License"); you may not use this file except in
compliance with the License. You may obtain a copy of the License at
http://www.apache.org/licenses/LICENSE-2.0 Unless required by applicable law or
agreed to in writing, software distributed under the License is distributed on
an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express
or implied. See the License for the specific language governing permissions and
limitations under the License

<template>
  <v-card class="text-center">
    <v-card-title
      @click="$emit('heading-clicked')"
      class="white--text primary text-body-2 mt-n1 justify-center"
    >
      {{ heading }}
    </v-card-title>
    <v-container>
      <v-row justify="end">
        <v-btn
          @click="$emit('close-dialog')"
          class="mt-n9 mr-2"
          color="white"
          small
          icon
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>
    </v-container>
    <v-container class="mt-n9">
      <slot></slot>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    heading: String,
  },
};
</script>
