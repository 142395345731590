Copyright 2022 Bio-Conversion Databank Foundation Licensed under the Apache
License, Version 2.0(the "License"); you may not use this file except in
compliance with the License. You may obtain a copy of the License at
http://www.apache.org/licenses/LICENSE-2.0 Unless required by applicable law or
agreed to in writing, software distributed under the License is distributed on
an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express
or implied. See the License for the specific language governing permissions and
limitations under the License

<template>
  <v-app
    id="main"
    :style="{ background: $vuetify.theme.themes[theme].background }"
  >
    <Notification />
    <v-main>
      <router-view />
    </v-main>
    <v-tour name="dashboardTour" :steps="dashboardTourSteps" />
    <v-tour name="phylotreeTour" :steps="phylotreeTourSteps" />
    <v-tour name="phredTour" :steps="phredTourSteps" />
    <v-tour name="comparisonTour" :steps="comparisonTourSteps" />
    <v-tour name="gemDashboardTour" :steps="gemDashboardTourSteps" />
  </v-app>
</template>

<script>
import Notification from '@/components/notification/Notification';
import ProjectHotbar from '@/components/project/ProjectHotbar';
import ProjectHeader from '@/components/project/ProjectHeader';
export default {
  name: 'App',
  components: { Notification, ProjectHotbar, ProjectHeader },
  data() {
    return {
      dashboardTourSteps: [
        {
          target: '.tour-dashboard-search-bar',
          content:
            'Use this search bar to query for anything in our database. Navigate to exactly what you need by selecting a search result from the category drop down(s).',
          params: {
            enableScrolling: false,
          },
        },
      ],
      gemDashboardTourSteps: [
        {
          target: '.tour-view-gem-analysis',
          content:
            'Welcome to the GEM Dashboard, click here to view the GEM analysis for the organism on the left',
          params: {
            enableScrolling: false,
          },
        },
      ],
      // steps of the Phylotree virtual tour
      phylotreeTourSteps: [
        {
          target: '.tour-phylotree-select-bacterial-group',
          content:
            'Welcome to the Phylotree, select which bacterial group you would like to visualize.',
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '.tour-phylotree-select-bacterial-group',
          content:
            'Use your mouse wheel or trackpad to zoom in/out, hover over the outer rings or rectangles to quickly view data, click on a node to collapse its branches, and click on a species name to discover more information.',
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '.tour-phylotree-main-menu',
          content:
            'Select from a variety of menu options to modify the tree view.',
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '.tour-phylotree-recenter-tree',
          content: 'Recenter the tree on your screen to regain focus.',
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '.tour-phylotree-search-species',
          content:
            'The search option can be used to quickly search organisms of interest, which will be highlighted on the tree.',
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '.tour-phylotree-comparison-menu',
          content:
            'Organisms of interest can be compared further in the Comparison Menu of the portal.',
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '.tour-phylotree-ring-menu',
          content:
            'This is the ring menu, where you can add information (GC content, groupings, pathways, etc.) as rings around the tree.',
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '.tour-phylotree-toggle-magnification',
          content: 'Use the zoom feature to view the tree in closer detail.',
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '.tour-phylotree-pathway-visualization',
          content:
            'This is the pathway menu, which shows several pathways. The presence/absence of genes involved in each pathway can be visualized for each organism as rings around the tree.',
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '.tour-phylotree-save-projects',
          content:
            "When you're satisfied with your current tree layout, you can save it as a project, which can also be shared with collaborators.",
          params: {
            enableScrolling: false,
          },
        },
      ],
      // steps of the Phred virtual tour
      phredTourSteps: [
        {
          target: '.tour-phred-title',
          content: 'Welcome to Phage Receptor Database (PhReD).',
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '.tour-phred-search',
          content: 'Use this search bar to scan every column in the table.',
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '.tour-phred-search-by',
          content:
            'Or search a specific column by selecting it from the dropdown.',
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '.tour-phred-keyboard',
          content:
            'Expand the virtual keyboard to have an easy access to complex characters when searching.',
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '.tour-phred-table-header',
          content:
            'Click on the the table header to sort the table by that column.',
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '.tour-phred-table-reference',
          content:
            'Open the collapsible row to see additional information including a link to the original paper.',
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '.tour-phred-filter-images',
          content:
            'Visual filter is the most intuitive way to search the table. Click on any phage image to give it a try.',
          params: {
            enableScrolling: false,
          },
        },
        {
          target: '.tour-phred-export-csv',
          content:
            "When you're satisfied with your search, the table can be exported as a .csv file.",
        },
        {
          target: '.tour-phred-save-project',
          content:
            "When you're satisfied with your current table layout, you can save it as a project, which can also be shared with collaborators.",
        },
        {
          target: '.tour-phred-add-receptor',
          content:
            'If you know any phage receptor that is not yet on PhReD, please provide all the needed information here. Your suggested receptors are only visible to you, which you can edit until it is verified. Verified receptors will then be added to the main PhReD table.',
        },
        {
          target: '.tour-phred-report-receptor',
          content:
            'For incorrect receptor information or other issues within this page, please report them here.',
        },
        {
          target: '.tour-phred-cite',
          content:
            "Please don't forget to cite PhReD if you use it in your work.",
        },
      ],
      // steps of the Comparison virtual tour
      comparisonTourSteps: [
        {
          target: '.tour-comparison-welcome',
          content:
            'Welcome to the Comparison Menu. Here you can do more focused comparisons for specific organisms using different data.',
        },
        {
          target: '.tour-comparison-select-bacteria',
          content:
            'Select a bacterial group to expand options for the selection of specific strains. Click on any bacterial group to give it a try.',
        },
        {
          target: '.tour-comparison-add-chart',
          content:
            "Once you're happy with your selection, you can add a chart. Clicking the button will allow you to select the chart type and appropriate data you want to show.",
        },
        {
          target: '.tour-comparison-wide-layout',
          content:
            'You can reformat the layout of your different charts. You can click on this button to switch ALL your charts to a wide layout. Each chart will also have a wide layout option if you prefer to only have some charts as wide.',
        },
        {
          target: '.tour-comparison-clear-all-charts',
          content:
            'This button clears ALL your charts. Each chart will also have a delete option.',
        },
        {
          target: '.tour-comparison-save-project',
          content:
            "When you're satisfied with your current charts layout, you can save it as a project, which can also be shared with collaborators.",
        },
      ],
    };
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    },
  },
};
</script>
