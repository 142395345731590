var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","height":"30"}}):_vm._e(),(_vm.availableSubproject)?_c('v-data-table',{key:_vm.subprojectListKey,staticClass:"elevation-0 mt-1",attrs:{"headers":_vm.headers,"items":_vm.availableSubproject,"search":_vm.search,"footer-props":{ itemsPerPageOptions: [5] },"expanded":_vm.expanded,"single-select":"","single-expand":"","item-key":"id"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var expand = ref.expand;
return [_c('tr',{key:item.id,class:_vm.selectedStyle(item.name),on:{"click":function($event){return _vm.changeSelected(item)},"dblclick":function () { return _vm.openSubproject(_vm.selectedSubproject); }}},[_c('td',[_c('SubprojectName',{attrs:{"item":item,"renamedSubproject":_vm.renamedSubproject,"subprojectMetadata":_vm.subprojectMetadata,"currentSubproject":_vm.currentSubproject,"availableSubprojectNames":_vm.availableSubprojectNames},on:{"reset-selected":_vm.resetSelected}})],1),_c('td',[_vm._v(_vm._s(item.updatedAt.substring(0, 10)))]),_c('td',[_vm._v(_vm._s(_vm.subprojectMetadata[item.__typename].name))]),_c('td',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function () {
                    _vm.renamedSubproject.id = item.id;
                    _vm.renamedSubproject.type =
                      _vm.subprojectMetadata[item.__typename].name;
                  }}},[_c('v-list-item-title',[_vm._v("Rename")])],1),_c('v-list-item',{on:{"click":function () {
                    _vm.selectedExpanded = 'delete';
                    expand(true);
                  }}},[_c('v-list-item-title',[_vm._v("Delete")])],1),_c('v-list-item',{on:{"click":function () {
                    _vm.selectedExpanded = 'move';
                    expand(true);
                  }}},[_c('v-list-item-title',[_vm._v("Copy and Move")])],1)],1)],1)],1)])]}},{key:"expanded-item",fn:function(ref){
                  var headers = ref.headers;
                  var item = ref.item;
return [_c('td',{staticClass:"text-center",attrs:{"colspan":headers.length}},[(_vm.selectedExpanded === 'move')?_c('SubprojectMove',{attrs:{"item":item,"subprojectMetadata":_vm.subprojectMetadata,"listComparisonCharts":_vm.listComparisonCharts.items,"listProjects":_vm.listProjects,"currentSubproject":_vm.currentSubproject},on:{"close-expanded":function($event){return _vm.expanded.pop()},"reset-selected":_vm.resetSelected}}):_vm._e(),(_vm.selectedExpanded === 'delete')?_c('SubprojectDelete',{attrs:{"selectedProject":_vm.selectedProject,"item":item,"subprojectMetadata":_vm.subprojectMetadata,"listComparisonCharts":_vm.listComparisonCharts.items,"currentSubproject":_vm.currentSubproject},on:{"close-expanded":function($event){return _vm.expanded.pop()},"update-subprojects":_vm.updateSubprojects,"reset-selected":_vm.resetSelected}}):_vm._e(),(_vm.selectedExpanded === 'share')?_c('SubprojectShare',{attrs:{"item":item,"subprojectMetadata":_vm.subprojectMetadata,"currentSubproject":_vm.currentSubproject},on:{"close-expanded":function($event){return _vm.expanded.pop()},"update-subproject":function (e) {
              _vm.$emit('update-subproject', e);
            }}}):_vm._e(),_c('v-divider',{staticClass:"mt-4"})],1)]}},{key:"footer",fn:function(){return [(_vm.showCreateButton)?_c('v-btn',{staticClass:"mt-1 ml-1",staticStyle:{"position":"absolute"},attrs:{"color":"primary","small":""},on:{"click":function($event){_vm.addSubProjectDialog = true}}},[_vm._v(" New SubProject "),_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")])],1):_vm._e()]},proxy:true}],null,false,445157032),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.addSubProjectDialog),callback:function ($$v) {_vm.addSubProjectDialog=$$v},expression:"addSubProjectDialog"}},[_c('AddSubProjectDialog',{attrs:{"selectedProject":_vm.selectedProject,"updateSubprojects":_vm.updateSubprojects,"addSubProjectDialog":_vm.addSubProjectDialog,"isSaveAs":false},on:{"close-dialog":function($event){return _vm.addProject()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }