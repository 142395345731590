Copyright 2022 Bio-Conversion Databank Foundation Licensed under the Apache
License, Version 2.0 (the "License"); you may not use this file except in
compliance with the License. You may obtain a copy of the License at
http://www.apache.org/licenses/LICENSE-2.0 Unless required by applicable law or
agreed to in writing, software distributed under the License is distributed on
an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express
or implied. See the License for the specific language governing permissions and
limitations under the License

<template>
  <v-list dense class="mt-1">
    <v-list-item-group
      mandatory
      v-model="selectedTabs"
      color="primary"
      class="mt-n2"
    >
      <v-list-item @click="$emit('resetShow')">
        <v-list-item-content>
          <v-list-item-title class="text-subtitle-1 text-center"
            >Open</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
      <v-btn
        class="text-subtitle-1 font-weight-medium text-capitalize rounded-0 ml-0 mt-0 mb-0"
        elevation="0"
        color="white"
        @click="saveSubproject"
        large
        block
      >
        Save
      </v-btn>
      <v-list-item @click="$emit('resetShow')">
        <v-list-item-content>
          <v-list-item-title class="text-subtitle-1 text-center"
            >Save as</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="$emit('resetShow')">
        <v-list-item-content>
          <v-list-item-title class="text-subtitle-1 text-center"
            >Create
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: 'ProjectDialogTabs',
  props: {
    currentSubproject: Object,
  },
  data() {
    return {
      selectedTabs: 0,
    };
  },
  watch: {
    selectedTabs: function () {
      // change tab in the main project dialog
      this.$emit('change-tab', this.selectedTabs);
    },
  },
  methods: {
    saveSubproject() {
      // save if a subproject is opened
      if (Object.keys(this.currentSubproject).length !== 0) {
        this.$emit('save-subproject');
      } else {
        // otherwise redirect to save as
        this.selectedTabs = 1;
      }
    },
    resetTabs() {
      this.selectedTabs = 0;
    },
  },
};
</script>

<style></style>
