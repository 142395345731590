export const namespaced = true;

export const state = {
  listOfPagesWithRestrictedAccess: [],
  fetchedPagesWithRestrictedAccess: false,
};

export const mutations = {
  SET_PAGES_WITH_RESTRICTED_ACCESS(state, value) {
    state.listOfPagesWithRestrictedAccess = value;
    // set fetchedPagesWithRestrictedAccess to true as we have loaded the pages
    state.fetchedPagesWithRestrictedAccess = true;
  },
};

export const actions = {
  setPagesWithRestrictedAccess({ commit }, value) {
    commit('SET_PAGES_WITH_RESTRICTED_ACCESS', value);
  },
};

export const getters = {
  getLoadingStateOfRestrictedAccess(state) {
    return state.fetchedPagesWithRestrictedAccess;
  },
};
