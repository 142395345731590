Copyright 2022 Bio-Conversion Databank Foundation Licensed under the Apache
License, Version 2.0 (the "License"); you may not use this file except in
compliance with the License. You may obtain a copy of the License at
http://www.apache.org/licenses/LICENSE-2.0 Unless required by applicable law or
agreed to in writing, software distributed under the License is distributed on
an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express
or implied. See the License for the specific language governing permissions and
limitations under the License

<template>
  <v-row class="ma-0" @click="$emit('reset-selected')">
    <v-col>
      <v-autocomplete
        :items="listProjects ? listProjects.items : []"
        item-text="projectName"
        item-value="id"
        label="Select a project"
        v-model="selectedProject"
        outlined
        dense
        single-line
        hide-details
      >
        <template v-slot:prepend-item>
          <v-list-item @click="$emit('ToggleShow')">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon small> mdi-plus </v-icon>
                Create a New Project
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        class="mt-n2 mx-2"
        single-line
        hide-details
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ProjectDialogSearch',
  props: {
    // List of all projects
    listProjects: Object,
    selectedProjectId: String,
  },
  data() {
    return {
      selectedProject: '',
      search: '',
    };
  },
  method: {},
  watch: {
    selectedProject: function () {
      this.$emit('change-project', this.selectedProject);
    },
    search: function () {
      this.$emit('search', this.search);
    },
  },
  created() {
    /*
    this.listProjects will always be undefined the first time the window is opened
    */
    if (!!this.selectedProjectId) {
      this.selectedProject = this.selectedProjectId;
    }
  },
};
</script>

<style></style>
