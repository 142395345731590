Copyright 2022 Bio-Conversion Databank Foundation Licensed under the Apache
License, Version 2.0 (the "License"); you may not use this file except in
compliance with the License. You may obtain a copy of the License at
http://www.apache.org/licenses/LICENSE-2.0 Unless required by applicable law or
agreed to in writing, software distributed under the License is distributed on
an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express
or implied. See the License for the specific language governing permissions and
limitations under the License

<template>
  <div>
    <v-combobox
      v-model="sharedUsersEmail"
      outlined
      dense
      small-chips
      label="Collaborators to share with"
      persistent-hint
      hint="Input the user's email address"
      multiple
      clearable
      deletable-chips
      class="mx-16 mt-4"
    ></v-combobox>
    <v-checkbox class="ml-14 mt-n2" label="Allow Write Access"></v-checkbox>
    <v-btn
      color="primary"
      outlined
      class="mx-1"
      @click="$emit('close-expanded')"
    >
      Cancel
    </v-btn>
    <v-btn color="primary" @click="shareSubproject"> Share </v-btn>
  </div>
</template>

<script>
import {
  CognitoIdentityProviderClient,
  ListUsersCommand,
  AdminListGroupsForUserCommand,
} from '@aws-sdk/client-cognito-identity-provider';
import {
  updatePhredSubProject,
  updatePhyloTreeSubProject,
  updateComparisonSubProject,
  updateProject,
} from '@/graphql/mutations.js';

import {
  getProject,
  getPhredSubProject,
  getPhyloTreeSubProject,
  getComparisonSubProject,
} from '@/graphql/queries.js';
const REGION = 'us-west-2';
const {
  fromCognitoIdentityPool,
} = require('@aws-sdk/credential-provider-cognito-identity');
const { CognitoIdentityClient } = require('@aws-sdk/client-cognito-identity');

export default {
  name: 'SubprojectShare',
  props: {
    item: Object,
    subprojectMetadata: Object,
    currentSubproject: Object,
  },
  data() {
    return {
      sharedUsersEmail: [],
      sharedUsersID: [],
      userList: [],
      emailMap: {},
    };
  },
  created() {
    this.getUsers();
  },
  methods: {
    async shareSubproject() {
      this.sharedUsersEmail.forEach((sharedUser) => {
        this.userList.forEach((user) => {
          if (sharedUser === user.email) {
            this.sharedUsersID.push(user.username);
          }
        });
      });
      let getProjectResult = {};
      let getSubProjectResult = {};
      try {
        getProjectResult = await this.$apollo.query({
          query: getProject,
          variables: {
            id: this.item.projectId,
          },
        });
        getSubProjectResult = await this.$apollo.query({
          query: this.getQuery(
            this.subprojectMetadata[this.item.__typename].name,
          ),
          variables: {
            id: this.item.id,
          },
        });
      } catch (error) {
        console.log(error);
      }
      const currentSubProjectSharedUsers = getSubProjectResult.data[
        'get' +
          this.subprojectMetadata[this.item.__typename].name +
          'SubProject'
      ].sharedUsers
        ? getSubProjectResult.data[
            'get' +
              this.subprojectMetadata[this.item.__typename].name +
              'SubProject'
          ].sharedUsers
        : [];

      const currentProjectSharedUsers = getProjectResult.data.getProject
        .sharedUsers
        ? getProjectResult.data.getProject.sharedUsers
        : [];

      for (let i = 0; i < this.sharedUsersID.length; i++) {
        if (currentSubProjectSharedUsers.includes(this.sharedUsersID[i])) {
          const notification = {
            type: 'error',
            message: 'Project already shared with user',
          };
          this.$store.dispatch('addNotification', notification);
          this.sharedUsersEmail = [];
          this.getUsers();
          return;
        }
      }

      this.sharedUsersID.forEach(async (user) => {
        currentSubProjectSharedUsers.push(user);
        try {
          if (!currentProjectSharedUsers.includes(user)) {
            currentProjectSharedUsers.push(user);
            const result = await this.$apollo.mutate({
              mutation: updateProject,
              variables: {
                input: {
                  id: this.item.projectId,
                  sharedUsers: currentProjectSharedUsers,
                },
              },
            });
          }
          const result = await this.$apollo.mutate({
            mutation: this.updateQuery(
              this.subprojectMetadata[this.item.__typename].name,
            ),
            variables: {
              input: {
                id: this.item.id,
                sharedUsers: currentSubProjectSharedUsers,
              },
            },
          });
        } catch (error) {
          const notification = {
            type: 'error',
            message: 'Something went wrong',
          };
          this.$store.dispatch('addNotification', notification);
          return;
        }
      });
      const notification = {
        type: 'success',
        message: 'Project sharing updated',
      };
      this.$store.dispatch('addNotification', notification);
      this.$emit('close-expanded');
    },
    updateQuery(type) {
      if (type === 'Phred') {
        return updatePhredSubProject;
      } else if (type === 'PhyloTree') {
        return updatePhyloTreeSubProject;
      } else if (type === 'Comparison') {
        return updateComparisonSubProject;
      }
    },
    getQuery(type) {
      if (type === 'Phred') {
        return getPhredSubProject;
      } else if (type === 'PhyloTree') {
        return getPhyloTreeSubProject;
      } else if (type === 'Comparison') {
        return getComparisonSubProject;
      }
    },
    getUsers(client = null, paginationToken = undefined) {
      if (client === null) {
        // Register a new client
        let cognitoProviderName =
          'cognito-idp.' +
          REGION +
          '.amazonaws.com/' +
          process.env.VUE_APP_COGNITO_POOL_ID;
        var client = new CognitoIdentityProviderClient({
          region: REGION,
          credentials: fromCognitoIdentityPool({
            client: new CognitoIdentityClient({ region: REGION }),
            identityPoolId:
              process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID_MANAGEUSERSANDGROUPS,
            logins: {
              [`${cognitoProviderName}`]:
                this.$store.state.user.session.idToken.jwtToken,
            },
          }),
        });
      }

      // Get list of all users
      const input = {
        UserPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
        PaginationToken: paginationToken,
      };
      const command = new ListUsersCommand(input);
      client.send(command).then((data) => {
        for (let i = 0; i < data.Users.length; i++) {
          let single_user = {};
          for (let j = 0; j < data.Users[i].Attributes.length; j++) {
            if (data.Users[i].Attributes[j].Name == 'sub') {
              single_user.username = data.Users[i].Attributes[j].Value;
            }
            if (data.Users[i].Attributes[j].Name == 'email') {
              single_user.email = data.Users[i].Attributes[j].Value;
            }
            // Breakout to save time
            if (
              single_user.email != undefined &&
              single_user.username != undefined
            ) {
              break;
            }
          }
          single_user.date_created = data.Users[i].UserCreateDate;

          // Must get user groups and THEN push the user
          this.emailMap[single_user.email] = single_user.username;
          this.getUserGroups(client, single_user);
        }

        // Paginate if necessary
        if (data.PaginationToken != undefined) {
          this.getUsers(client, data.PaginationToken);
        }
      });
    },
    getUserGroups(client, single_user, nextToken = undefined) {
      var input = {
        UserPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
        Username: single_user.username,
        NextToken: nextToken,
      };
      var command = new AdminListGroupsForUserCommand(input);
      client
        .send(command)
        .then((data) => {
          single_user.groups = [];
          for (let i = 0; i < data.Groups.length; i++) {
            single_user.groups.push(data.Groups[i].GroupName);
          }
          this.userList.push(single_user);

          this.item.sharedUsers.forEach((sharedUser) => {
            if (sharedUser === single_user.username) {
              this.sharedUsersEmail.push(single_user.email);
            }
          });

          // Paginate if necessary
          if (data.NextToken != undefined) {
            this.getUserGroups(client, single_user, data.NextToken);
          }
        })
        .catch((err) => {
          // console.error(err) // DEBUG
          const notification = {
            type: 'error',
            message: err.message,
          };
          this.$store.dispatch('addNotification', notification);
        });
    },
  },
};
</script>

<style></style>
