Copyright 2022 Bio-Conversion Databank Foundation Licensed under the Apache
License, Version 2.0 (the "License"); you may not use this file except in
compliance with the License. You may obtain a copy of the License at
http://www.apache.org/licenses/LICENSE-2.0 Unless required by applicable law or
agreed to in writing, software distributed under the License is distributed on
an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express
or implied. See the License for the specific language governing permissions and
limitations under the License

<template>
  <Dialog
    heading="Add A New SubProject"
    @close-dialog="
      () => {
        $emit('close-dialog');
        resetForm();
      }
    "
  >
    <v-col cols="auto">
      <v-menu
        v-if="isSaveAs"
        open-on-hover
        nudge-bottom="0"
        allow-overflow
        max-height="500"
      >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" outlined width="500" class="mr-5">
            {{
              selectedProjectObject
                ? `Selected Project: ${selectedProjectObject.projectName}`
                : 'Select Project'
            }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="showCreateProject = true">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon small> mdi-plus </v-icon>
                Create a New Project
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
          <v-list-item
            v-for="(item, index) in projects"
            :key="index"
            @click="
              () => {
                selectedProjectObject = item;
              }
            "
          >
            <v-list-item-title>{{ item.projectName }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-select
        v-if="!isSaveAs"
        :items="items"
        label="Select your new SubProject type"
        dense
        outlined
        class="mt-3"
        height="40"
        v-model="selectedSubprojectType"
        ref="selectSubprojectType"
        item-name="name"
        item-value="name"
        return-object
      >
        <template v-slot:selection="{ item }">
          <v-icon v-if="item.icon" color="primary" height="30" width="30">{{
            item.icon
          }}</v-icon>
          <v-img
            v-if="item.image"
            :src="item.image"
            max-height="30"
            max-width="30"
          /><span class="ml-4">{{ item.name }} </span>
        </template>

        <template v-slot:item="{ item }">
          <v-icon v-if="item.icon" height="30" width="30" color="primary">{{
            item.icon
          }}</v-icon>
          <v-img
            v-if="item.image"
            :src="item.image"
            max-height="30"
            max-width="30"
          />
          <span class="ml-4">{{ item.name }} </span>
        </template>
      </v-select>

      <v-form class="ml-4 mt-4" ref="textSubmit">
        <v-text-field
          v-model="saveas"
          label="New SubProject Name"
          outlined
          height="40"
          dense
          clearable
          class="select"
          :rules="saveasRules"
          @keyup.enter="isSaveasValid && saveSubproject()"
        >
        </v-text-field>
        <v-btn class="mx-1 mt-3" color="error" @click="$emit('close-dialog')">
          Cancel
        </v-btn>
        <v-btn
          class="ml-4 mt-3"
          color="primary"
          @click="saveSubproject"
          :disabled="!isFormValid"
        >
          Create
        </v-btn>
      </v-form>
    </v-col>
    <v-divider />
    <div v-if="showCreateProject">
      <v-row>
        <v-spacer />
        <v-col sm="11">
          <v-text-field
            label="Enter New Project Name"
            outlined
            clearable
            dense
            v-model="newprojectName"
            class="mt-10"
          >
          </v-text-field>
        </v-col>
        <v-spacer />
      </v-row>
      <v-row class="mt-n10">
        <v-spacer />
        <v-btn
          :disabled="newprojectName.length == 0"
          @click="createProject"
          color="primary"
          class="mt-10 mb-10"
        >
          Create
        </v-btn>
        <v-spacer />
      </v-row>
    </div>
  </Dialog>
</template>

<script>
import { validateNotEmpty } from '@/utilities/validationRules';
import { subprojectSaveas, subprojectSave } from '@/mixins/project';
import { listProjects, getProject } from '@/graphql/queries.js';
import { createProject } from '@/graphql/mutations.js';
import Dialog from '@/components/Dialog';
export default {
  name: 'AddSubProjectDialog',
  components: { Dialog },
  mixins: [subprojectSaveas, subprojectSave],
  props: {
    subprojectMetadata: Object,
    dialogSelectedProject: String,
    item: Object,
    addSubProjectDialog: Boolean,
    isSaveAs: Boolean,
    selectedProject: String,
  },
  data() {
    return {
      newprojectName: '',
      showCreateProject: false,
      selectedSubprojectType: null,
      availableSubprojectNames: [],
      selectedProjectObject: null,
      selectedSubproject: null,
      saveas: '',
      items: [
        {
          name: 'Phred',
          image: require('@/assets/icon/phage.svg'),
        },
        { name: 'PhyloTree', icon: 'mdi-file-tree' },
        { name: 'Comparison', icon: 'mdi-ab-testing' },
      ],
      projectName: '',
      usedNames: [],
      saveasRules: [
        validateNotEmpty,
        (e) => {
          if (this.availableSubprojectNames.includes(e)) {
            return 'Name is already used';
          }
          return true;
        },
      ],
    };
  },
  computed: {
    projects() {
      return this.listProjects.items;
    },
    //If subproject dialog is in saveas state then check if user selected a project, if not check if a subproject type is selected
    isFormValid: {
      get() {
        if (this.isSaveAs) {
          return this.saveas != null && this.selectedProjectObject != null;
        } else {
          return this.saveas && this.selectedSubprojectType;
        }
      },
      set(value) {
        this.isFormValid = value;
      },
    },
  },
  methods: {
    validateNotEmpty,
    //reseting form back to default state when closed
    createProject() {
      const projectName = this.newprojectName;
      this.$apollo
        .mutate({
          mutation: createProject,
          variables: {
            input: {
              owner: this.$store.state.user.userAttributes['cognito:username'],
              projectName,
              userId: this.$store.state.user.userAttributes['cognito:username'],
              usersShareAccess: false,
              usersDeleteAccess: false,
              usersUpdateAccess: false,
            },
          },
        })
        .then((data) => {
          const notification = {
            type: 'success',
            message: 'Project is successfully created',
          };
          this.refetchProjects();
          this.$store.dispatch('addNotification', notification);
          this.newprojectName = ' ';
          this.showCreateProject = false;
          this.selectedProjectObject = data.data.createProject;
        })
        .catch((err) => {
          console.log(err); // DEBUG
          const notification = {
            type: 'error',
            message: 'Something went wrong',
          };
          this.$store.dispatch('addNotification', notification);
        });
    },
    refetchProjects() {
      this.$apollo.queries.listProjects.refetch();
    },
    resetForm() {
      this.$refs.textSubmit.reset();
      this.selectedProjectObject = 0;
      this.selectedSubProjectType = 0;
      this.showCreateProject = false;
    },
    async saveSubproject() {
      if (!this.selectedProject) {
        this.selectedProject = this.selectedProjectObject.id;
      }
      const result = await this.$apollo.query({
        query: getProject,
        variables: {
          id: this.selectedProject,
        },
      });
      const usersUpdateAccess = result.data.getProject.usersUpdateAccess;
      const ownerId = result.data.getProject.owner;
      if (
        usersUpdateAccess == false &&
        this.$store.state.user.userAttributes['cognito:username'] != ownerId
      ) {
        const notification = {
          type: 'error',
          message: 'You do not have permission to perform this task',
        };
        this.$store.dispatch('addNotification', notification);
        return;
      }

      //Only ask user for subproject type add suproject dialog is not save as
      if (!this.isSaveAs) {
        if (this.selectedSubprojectType.name === 'Phred') {
          this.saveasPhredSubproject();
        }
        if (this.selectedSubprojectType.name === 'PhyloTree') {
          this.saveasPhyloTreeSubproject();
        }
        if (this.selectedSubprojectType.name === 'Comparison') {
          this.saveasComparisonSubproject();
        }
        //If user is using saveas then save project based off current page they are on
      } else {
        if (this.$route.name === 'Phred') {
          this.saveasPhredSubproject();
        }
        if (this.$route.name === 'PhyloTree') {
          this.saveasPhyloTreeSubproject();
        }
        if (this.$route.name === 'Comparison') {
          this.saveasComparisonSubproject();
        }
      }
    },
  },
  watch: {
    addSubProjectDialog(newVal) {
      if (!newVal) {
        this.resetForm();
      }
    },
  },
  apollo: {
    listProjects: {
      query: listProjects,
      error(err) {
        console.log(err);
      },
    },
  },
};
</script>

<style>
.select {
  width: 500px;
}
</style>
