Copyright 2022 Bio-Conversion Databank Foundation Licensed under the Apache
License, Version 2.0 (the "License"); you may not use this file except in
compliance with the License. You may obtain a copy of the License at
http://www.apache.org/licenses/LICENSE-2.0 Unless required by applicable law or
agreed to in writing, software distributed under the License is distributed on
an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express
or implied. See the License for the specific language governing permissions and
limitations under the License

<template>
  <div>
    <v-icon
      class="mr-2"
      :class="item.id === renamedSubproject.id ? 'float-left mt-3' : ''"
      v-if="subprojectMetadata[item.__typename].icon"
      color="primary"
    >
      {{ subprojectMetadata[item.__typename].icon }}
    </v-icon>
    <v-img
      class="float-left mr-2"
      :class="item.id === renamedSubproject.id ? 'mt-2' : ''"
      v-if="subprojectMetadata[item.__typename].image"
      max-width="25"
      :src="
        require(`@/assets/icon/${subprojectMetadata[item.__typename].image}`)
      "
    ></v-img>
    <template v-if="item.id !== renamedSubproject.id">
      {{ item.name }}
    </template>
    <template v-else>
      <v-form v-model="isRenameValid">
        <v-text-field
          :placeholder="item.name"
          v-model="rename"
          :rules="renameRules"
          @keydown.enter="isRenameValid && renameSubproject()"
          dense
        >
          <template slot="append">
            <v-icon @click="$emit('reset-selected')">mdi-close</v-icon>
            <v-icon v-show="isRenameValid" @click="renameSubproject">
              mdi-check
            </v-icon>
          </template>
        </v-text-field>
      </v-form>
    </template>
  </div>
</template>

<script>
import {
  updatePhredSubProject,
  updatePhyloTreeSubProject,
  updateComparisonSubProject,
} from '@/graphql/mutations.js';
import { validateNotEmpty } from '@/utilities/validationRules';

import { subprojectUpdate } from '@/mixins/project';

export default {
  name: 'SubprojectName',
  mixins: [subprojectUpdate],
  props: {
    item: Object,
    renamedSubproject: Object,
    subprojectMetadata: Object,
    currentSubproject: Object,
    availableSubprojectNames: Array,
  },
  data() {
    return {
      rename: '',
      isRenameValid: false,
      renameRules: [
        validateNotEmpty,
        (e) => {
          if (this.availableSubprojectNames.includes(e)) {
            return 'Name is already used';
          }
          return true;
        },
      ],
    };
  },
  methods: {
    renameSubproject() {
      this.$apollo
        .mutate({
          mutation: this.updateQuery(this.renamedSubproject.type),
          variables: {
            input: {
              id: this.renamedSubproject.id,
              name: this.rename,
            },
          },
        })
        .then((data) => {
          this.$emit('reset-selected');
          this.rename = '';
          // dummy data if changed file is opened
          if (
            !!this.currentSubproject &&
            this.currentSubproject.id === this.renamedSubproject.id
          ) {
            this.updateSubproject('name', this.rename);
          }
          const notification = {
            type: 'success',
            message: 'Subproject is successfully renamed',
          };
          this.$store.dispatch('addNotification', notification);
        })
        .catch((err) => {
          console.log(err); // DEBUG
          const notification = {
            type: 'error',
            message: 'Could not rename subproject',
          };
          this.$store.dispatch('addNotification', notification);
        });
    },
    updateQuery(type) {
      if (type === 'Phred') {
        return updatePhredSubProject;
      } else if (type === 'PhyloTree') {
        return updatePhyloTreeSubProject;
      } else if (type === 'Comparison') {
        return updateComparisonSubProject;
      }
    },
  },
};
</script>

<style></style>
