`Copyright 2022 Bio-Conversion Databank Foundation

Licensed under the Apache License, Version 2.0(the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License`;

import { wordArrToEnum, camelToEnum } from '@/utilities/dataPreprocess.js';
import {
  updatePhredSubProject,
  updateComparisonSubProject,
  updateComparisonChart,
  createComparisonChart,
  deleteComparisonChart,
  updatePhyloTreeSubProject,
} from '@/graphql/mutations.js';
import {
  listProjects,
  listPhredSubProjects,
  listPhyloTreeSubProjects,
  listComparisonSubProjects,
  listComparisonCharts,
} from '@/graphql/queries.js';
export default {
  methods: {
    updateSubprojects() {
      this.$apollo.queries.listPhredSubProjects.refetch();
      this.$apollo.queries.listPhyloTreeSubProjects.refetch();
      this.$apollo.queries.listComparisonSubProjects.refetch();
      this.$apollo.queries.listComparisonCharts.refetch();
    },
    saveSubproject() {
      if (this.currentSubproject.type === 'Phred') {
        this.savePhredSubproject();
      } else if (this.currentSubproject.type === 'Comparison') {
        this.saveComparisonSubproject();
      } else if (this.currentSubproject.type === 'PhyloTree') {
        this.savePhyloTreeSubproject();
      }
    },
    savePhredSubproject() {
      let phred = this.$store.state.phred;
      if (phred.search === null) {
        phred.search = '';
      }
      this.$apollo
        .mutate({
          mutation: updatePhredSubProject,
          variables: {
            input: {
              id: this.currentSubproject.id,
              search: phred.search,
              filterReceptorLocation: phred.filterReceptorLocation,
              filterFamily: wordArrToEnum(phred.filterFamily),
              filterDifferentialStain: wordArrToEnum(phred.filterGramStain),
            },
          },
        })
        .then((data) => {
          this.$emit('close-dialog');
          this.updateSubprojects();
          const notification = {
            type: 'success',
            message: 'Subproject is successfully saved',
          };
          this.$store.dispatch('addNotification', notification);
        })
        .catch((err) => {
          console.log(err); // DEBUG
          const notification = {
            type: 'error',
            message: 'Could not save subproject',
          };
          this.$store.dispatch('addNotification', notification);
        });
    },
    saveComparisonSubproject() {
      let comparison = this.$store.state.comparison;
      // saving the subproject first
      this.$apollo
        .mutate({
          mutation: updateComparisonSubProject,
          variables: {
            input: {
              id: this.currentSubproject.id,
              organisms: comparison.species,
            },
          },
        })
        .then((data) => {
          this.$emit('close-dialog');
          this.updateSubprojects();
          const notification = {
            type: 'success',
            message: 'Subproject is successfully saved',
          };
          this.$store.dispatch('addNotification', notification);
        })
        .catch((err) => {
          console.log(err); // DEBUG
          const notification = {
            type: 'error',
            message: 'Could not save subproject',
          };
          this.$store.dispatch('addNotification', notification);
        });
      // query if subproject has the same charts in frontend and database
      if (
        (comparison.diagramList.length === 0 &&
          !this.currentSubproject.charts) ||
        comparison.diagramList.length === this.currentSubproject.charts.length
      ) {
        for (let i = 0; i < comparison.diagramList.length; i++) {
          let id = comparison.diagramList[i].id;
          let chart = 'chart' + id;
          this.$apollo
            .mutate({
              mutation: updateComparisonChart,
              variables: {
                input: {
                  id: this.currentSubproject.charts[i].id,
                  chartType: camelToEnum(comparison.charts[chart]),
                  parameters: comparison.properties[chart],
                },
              },
            })
            .then((data) => {
              // process only in the last iteration
              if (i === comparison.diagramList.length - 1) {
                this.updateSubprojects();
              }
            })
            .catch((err) => {
              console.log(err); // DEBUG
              const notification = {
                type: 'error',
                message: 'Could not save subproject',
              };
              this.$store.dispatch('addNotification', notification);
            });
        }
      } else {
        // delete charts in database
        if (!!this.currentSubproject.charts) {
          this.currentSubproject.charts.forEach((item) => {
            this.$apollo
              .mutate({
                mutation: deleteComparisonChart,
                variables: {
                  input: {
                    id: item.id,
                  },
                },
              })
              .catch((err) => {
                console.log(err); // DEBUG
                const notification = {
                  type: 'error',
                  message: 'Could not save subproject',
                };
                this.$store.dispatch('addNotification', notification);
              });
          });
        }
        // recreate charts from frontend to database
        comparison.diagramList.forEach((n, i) => {
          let chart = 'chart' + n.id;
          this.$apollo
            .mutate({
              mutation: createComparisonChart,
              variables: {
                input: {
                  projectId: this.currentSubproject.projectId,
                  subProjectId: this.currentSubproject.id,
                  owner:
                    this.$store.state.user.userAttributes['cognito:username'],
                  userId:
                    this.$store.state.user.userAttributes['cognito:username'],
                  chartType: camelToEnum(comparison.charts[chart]),
                  parameters: comparison.properties[chart],
                },
              },
            })
            .then((data) => {
              // process only in the last iteration
              if (i === comparison.diagramList.length - 1) {
                this.updateSubprojects();
              }
            })
            .catch((err) => {
              console.log(err); // DEBUG
              const notification = {
                type: 'error',
                message: 'Could not save subproject',
              };
              this.$store.dispatch('addNotification', notification);
            });
        });
      }
    },
    savePhyloTreeSubproject() {
      let phylotree = this.$store.state.phylotree;
      let subproject = {
        id: this.currentSubproject.id,
        alignLabels: phylotree.alignLabels ? 'YES' : 'NO',
        bacterialGroup: phylotree.bacterialGroup,
        branchLengths: phylotree.branchLengths ? 'YES' : 'NO',
        circular: phylotree.circular ? 'YES' : 'NO',
        displayBackgroundColor: phylotree.displayBackgroundColor ? 'YES' : 'NO',
        displayClostridiumPathogens: phylotree.displayClostridiumPathogens
          ? 'YES'
          : 'NO',
        displayGenomeLength: phylotree.displayGenomeLength ? 'YES' : 'NO',
        displayInnerNodes: phylotree.displayInnerNodes ? 'YES' : 'NO',
        displayLeafLabels: phylotree.displayLeafLabels ? 'YES' : 'NO',
        displayLeaves: phylotree.displayLeaves ? 'YES' : 'NO',
        displayLegend: phylotree.displayLegend ? 'YES' : 'NO',
        showMethanoGenus: phylotree.displayMethanoGenus ? 'YES' : 'NO',
        showOutgroup: phylotree.displayOutgroup ? 'YES' : 'NO',
        rings: phylotree.rings,
        displayOrder: phylotree.displayOrder,
      };
      //console.log(subproject)
      this.$apollo
        .mutate({
          mutation: updatePhyloTreeSubProject,
          variables: {
            input: subproject,
          },
        })
        .then((data) => {
          this.updateSubprojects();
          const notification = {
            type: 'success',
            message: 'Subproject is successfully saved',
          };
          this.$store.dispatch('addNotification', notification);
        })
        .catch((err) => {
          console.log(err); // DEBUG
          const notification = {
            type: 'error',
            message: 'Could not save subproject',
          };
          this.$store.dispatch('addNotification', notification);
        });
    },
  },
  apollo: {
    listProjects: {
      query: listProjects,
      error(err) {
        console.log(err);
      },
    },
    listPhredSubProjects: {
      query: listPhredSubProjects,
      error(err) {
        console.log(err);
      },
    },
    listPhyloTreeSubProjects: {
      query: listPhyloTreeSubProjects,
      error(err) {
        console.log(err);
      },
    },
    listComparisonSubProjects: {
      query: listComparisonSubProjects,
      error(err) {
        console.log(err);
      },
    },
    listComparisonCharts: {
      query: listComparisonCharts,
      error(err) {
        console.log(err);
      },
    },
  },
};
