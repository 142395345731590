Copyright 2022 Bio-Conversion Databank Foundation Licensed under the Apache
License, Version 2.0 (the "License"); you may not use this file except in
compliance with the License. You may obtain a copy of the License at
http://www.apache.org/licenses/LICENSE-2.0 Unless required by applicable law or
agreed to in writing, software distributed under the License is distributed on
an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express
or implied. See the License for the specific language governing permissions and
limitations under the License

<template>
  <div class="hot-bar">
    <v-app-bar style="width: 100%" height="50" elevation="1">
      <v-card-title>{{ this.header }}</v-card-title>
      <SubprojectChip :currentSubproject="activeSubproject" />
      <v-card-title v-if="isEdited">- Edited</v-card-title>
      <v-spacer />
      <v-card-title
        ><span class="blue--text ml-10">{{ selectedTree }} </span></v-card-title
      >
      <v-spacer />
      <ProjectHotbar />
    </v-app-bar>
  </div>
</template>

<script>
import { listProjects } from '@/graphql/queries.js';
import ProjectHotbar from '@/components/project/ProjectHotbar';
import SubprojectChip from '@/components/project/SubprojectChip';
export default {
  components: {
    ProjectHotbar,
    SubprojectChip,
  },
  props: { organism: String },
  computed: {
    selectedTree() {
      if (this.$route.name.includes('PhyloTree')) {
        return this.$store.state.project.selectedTree;
      } else {
        return '';
      }
    },
    isEdited() {
      return this.$store.state.project.isEdited;
    },
    header() {
      if (this.projectName) {
        return this.projectName + ': ';
      } else {
        return 'No Project Selected';
      }
    },
    currentSubproject: {
      get() {
        if (Object.keys(this.currentSubproject).length !== 0) {
          if (this.activeSubproject.type == 'Comparison') {
            return this.$store.state.comparison.currentSubproject;
          }
          if (this.activeSubproject.type == 'Phred') {
            return this.$store.state.phred.currentSubproject;
          }
          if (this.activeSubproject.type == 'PhyloTree') {
            return this.$store.state.phylotree.currentSubproject;
          }
        } else {
          return {};
        }
      },
      set(value) {
        this.$store.dispatch('comparison/setCurrentSubproject', value);
      },
    },
    activeSubproject() {
      return this.$store.state.project.activeSubproject;
    },
    projectName() {
      if (Object.keys(this.activeSubproject).length === 0) {
        return null;
      }
      let arr = this.listProjects.items.filter(
        (item) => item.id === this.activeSubproject.projectId,
      );
      // there will be only one project id that matches
      return arr[0].projectName;
    },
  },
  methods: {
    refetchProjects() {
      this.$apollo.queries.listProjects.refetch();
    },
  },
  apollo: {
    listProjects: {
      query: listProjects,
      error(err) {
        console.log(err);
      },
    },
  },
  mounted() {
    this.refetchProjects();
  },
};
</script>
