`Copyright 2022 Bio-Conversion Databank Foundation

Licensed under the Apache License, Version 2.0(the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License`;

//export const namespaced = true
export const state = {
  currentSubproject: {},
  // settings
  displayInnerNodes: true,
  branchLengths: true,
  displayLeaves: true,
  displayLeafLabels: true,
  alignLabels: true,
  circular: true,
  displayOutgroup: true,
  displayGenomeLength: true,
  displayBackgroundColor: true,
  displayLegend: false,
  displayMethanoGenus: true,
  displayClostridiumPathogens: true,
  displayCommonAncestors: true,
  magnificationPower: 23,
  // rings
  rings: [],
  displayOrder: [],
  ringColorMap: {},
  enzymes: [],
  bacterialGroup: null,
  selectedSpeciesInfo: null,
};

export const mutations = {
  SET_SELECTED_SPECIES_INFO(state, value) {
    state.selectedSpeciesInfo = value;
  },
  SET_BACTERIAL_GROUP(state, value) {
    state.bacterialGroup = value;
  },
  SET_CURRENT_SUBPROJECT(state, value) {
    state.currentSubproject = value;
  },
  // settings
  TOGGLE_DISPLAY_INNER_NODES(state, value) {
    state.displayInnerNodes = value;
  },
  TOGGLE_DISPLAY_BRANCH_LENGTHS(state, value) {
    state.branchLengths = value;
  },
  TOGGLE_DISPLAY_LEAVES(state, value) {
    state.displayLeaves = value;
  },
  TOGGLE_DISPLAY_LEAF_LABELS(state, value) {
    state.displayLeafLabels = value;
  },
  TOGGLE_ALIGN_LABELS(state, value) {
    state.alignLabels = value;
  },
  TOGGLE_CIRCULAR(state, value) {
    state.circular = value;
  },
  TOGGLE_DISPLAY_OUTGROUP(state, value) {
    state.displayOutgroup = value;
  },
  TOGGLE_DISPLAY_GENOME_LENGTH(state, value) {
    state.displayGenomeLength = value;
  },
  TOGGLE_DISPLAY_BACKGROUND_COLOR(state, value) {
    state.displayBackgroundColor = value;
  },
  TOGGLE_DISPLAY_LEGEND(state, value) {
    state.displayLegend = value;
  },
  TOGGLE_DISPLAY_METHANO_GENUS(state, value) {
    state.displayMethanoGenus = value;
  },
  TOGGLE_DISPLAY_COMMON_ANCESTORS(state, value) {
    state.displayCommonAncestors = value;
  },
  TOGGLE_DISPLAY_CLOSTRIDIUM_PATHOGENS(state, value) {
    state.displayClostridiumPathogens = value;
  },
  UPDATE_MAGNIFICATION_POWER(state, value) {
    state.magnificationPower = value;
  },
  // rings
  SET_RINGS(state, value) {
    state.rings = value;
  },
  SET_RING_COLOR_MAP(state, value) {
    state.ringColorMap = value;
  },
  DELETE_COLOR(state, value) {
    delete state.ringColorMap[value];
  },
  SET_ENZYMES(state, value) {
    state.enzymes = value;
  },
  SET_DISPLAY_ORDER(state, value) {
    state.displayOrder = value;
  },
  APPEND_RING(state, value) {
    state.rings.push(value);
    state.displayOrder.push(value);
  },
  ADD_RING(state) {
    state.rings.push('');
  },
  DELETE_RING(state, index) {
    const displayOrderIndex = state.displayOrder.indexOf(state.rings[index]);
    state.displayOrder.splice(displayOrderIndex, 1);
    state.rings.splice(index, 1);
    //state.rings = JSON.parse(JSON.stringify(state.displayOrder));
  },
  DELETE_DISPLAY_ITEM(state, index) {
    state.rings.splice(index, 1);
  },
  REORDER_RINGS(state, value) {
    // Need to do this splicing because Vue can't detect the array mutations (https://vuejs.org/v2/guide/reactivity.html)
    if (
      value.ringSelection.includes(';') ||
      value.ringSelection.includes('Genus') ||
      value.ringSelection.includes('Family') ||
      value.ringSelection.includes('GC')
    ) {
      state.displayOrder.splice(value.index, 1, value.ringSelection);
    }
    state.rings.splice(value.index, 1, value.ringSelection);
  },
  RESET_RING_MENU(state) {
    state.rings = [];
    state.displayOrder = [];
  },
};

export const actions = {
  setCurrentSubproject({ commit, dispatch }, value) {
    commit('SET_CURRENT_SUBPROJECT', value);
    if (Object.keys(value).length > 0) {
      // load subproject
      commit('SET_BACTERIAL_GROUP', value.bacterialGroup);
      commit('TOGGLE_ALIGN_LABELS', value.alignLabels === 'YES');
      commit('TOGGLE_DISPLAY_BRANCH_LENGTHS', value.branchLengths === 'YES');
      commit('TOGGLE_CIRCULAR', value.circular === 'YES');
      commit(
        'TOGGLE_DISPLAY_BACKGROUND_COLOR',
        value.displayBackgroundColor === 'YES',
      );
      commit(
        'TOGGLE_DISPLAY_CLOSTRIDIUM_PATHOGENS',
        value.displayClostridiumPathogens === 'YES',
      );
      commit(
        'TOGGLE_DISPLAY_GENOME_LENGTH',
        value.displayGenomeLength === 'YES',
      );
      commit('TOGGLE_DISPLAY_INNER_NODES', value.displayInnerNodes === 'YES');
      commit('TOGGLE_DISPLAY_LEAF_LABELS', value.displayLeafLabels === 'YES');
      commit('TOGGLE_DISPLAY_LEAVES', value.displayLeaves === 'YES');
      commit('TOGGLE_DISPLAY_LEGEND', value.displayLegend === 'YES');
      commit('TOGGLE_DISPLAY_METHANO_GENUS', value.showMethanoGenus === 'YES');
      commit(
        'TOGGLE_DISPLAY_COMMON_ANCESTORS',
        value.displayCommonAncestors === 'YES',
      );
      commit('TOGGLE_DISPLAY_OUTGROUP', value.showOutgroup === 'YES');
      commit('SET_RINGS', value.rings);
      commit('SET_DISPLAY_ORDER', value.displayOrder);
    } else {
      dispatch('closeCurrentSubproject');
    }
  },
  closeCurrentSubproject({ commit }) {
    commit('SET_BACTERIAL_GROUP', null);
    commit('TOGGLE_ALIGN_LABELS', true);
    commit('TOGGLE_DISPLAY_BRANCH_LENGTHS', true);
    commit('TOGGLE_CIRCULAR', true);
    commit('TOGGLE_DISPLAY_BACKGROUND_COLOR', true);
    commit('TOGGLE_DISPLAY_CLOSTRIDIUM_PATHOGENS', true);
    commit('TOGGLE_DISPLAY_GENOME_LENGTH', true);
    commit('TOGGLE_DISPLAY_INNER_NODES', true);
    commit('TOGGLE_DISPLAY_LEAF_LABELS', true);
    commit('TOGGLE_DISPLAY_LEAVES', true);
    commit('TOGGLE_DISPLAY_LEGEND', false);
    commit('TOGGLE_DISPLAY_METHANO_GENUS', true);
    commit('TOGGLE_DISPLAY_COMMON_ANCESTORS', true);
    commit('TOGGLE_DISPLAY_OUTGROUP', true);
    commit('SET_RINGS', []);
    commit('SET_DISPLAY_ORDER', []);
  },
  // settings
  toggleDisplayInnerNodes({ commit }, value) {
    commit('TOGGLE_DISPLAY_INNER_NODES', value);
  },
  toggleBranchLengths({ commit }, value) {
    commit('TOGGLE_DISPLAY_BRANCH_LENGTHS', value);
  },
  toggleDisplayLeaves({ commit }, value) {
    commit('TOGGLE_DISPLAY_LEAVES', value);
  },
  toggleDisplayLeafLabels({ commit }, value) {
    commit('TOGGLE_DISPLAY_LEAF_LABELS', value);
  },
  toggleAlignLabels({ commit }, value) {
    commit('TOGGLE_ALIGN_LABELS', value);
  },
  toggleCircular({ commit }, value) {
    commit('TOGGLE_CIRCULAR', value);
  },
  toggleDisplayOutgroup({ commit }, value) {
    commit('TOGGLE_DISPLAY_OUTGROUP', value);
  },
  toggleDisplayGenomeLength({ commit }, value) {
    commit('TOGGLE_DISPLAY_GENOME_LENGTH', value);
  },
  toggleDisplayBackgroundColor({ commit }, value) {
    commit('TOGGLE_DISPLAY_BACKGROUND_COLOR', value);
  },
  toggleDisplayLegend({ commit }, value) {
    commit('TOGGLE_DISPLAY_LEGEND', value);
  },
  toggleDisplayMethanoGenus({ commit }, value) {
    commit('TOGGLE_DISPLAY_METHANO_GENUS', value);
  },
  toggleDisplayCommonAncestors({ commit }, value) {
    commit('TOGGLE_DISPLAY_COMMON_ANCESTORS', value);
  },
  toggleDisplayClostridiumPathogens({ commit }, value) {
    commit('TOGGLE_DISPLAY_CLOSTRIDIUM_PATHOGENS', value);
  },
  updateMagnificationPower({ commit }, value) {
    commit('UPDATE_MAGNIFICATION_POWER', value);
  },
  // rings
  setRings({ commit }, value) {
    commit('SET_RINGS', value);
  },
  setRingColorMap({ commit }, value) {
    commit('SET_RING_COLOR_MAP', value);
  },
  setEnzymes({ commit }, value) {
    commit('SET_ENZYMES', value);
  },
  setDisplayOrder({ commit }, value) {
    commit('SET_DISPLAY_ORDER', value);
  },
  appendRing({ commit }, value) {
    commit('APPEND_RING', value);
  },
  addRing({ commit }) {
    commit('ADD_RING');
  },
  deleteRing({ commit }, index) {
    commit('DELETE_RING', index);
  },
  reorderRings({ commit }, value) {
    commit('REORDER_RINGS', value);
  },
  deleteDisplayItem({ commit }, index) {
    commit('DELETE_DISPLAY_ITEM', index);
  },
  resetRingMenu({ commit }) {
    commit('RESET_RING_MENU');
  },
  deleteColor({ commit }, value) {
    commit('DELETE_COLOR', value);
  },
};
