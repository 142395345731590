Copyright 2022 Bio-Conversion Databank Foundation Licensed under the Apache
License, Version 2.0 (the "License"); you may not use this file except in
compliance with the License. You may obtain a copy of the License at
http://www.apache.org/licenses/LICENSE-2.0 Unless required by applicable law or
agreed to in writing, software distributed under the License is distributed on
an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express
or implied. See the License for the specific language governing permissions and
limitations under the License

<template>
  <v-card>
    <v-progress-linear
      v-if="loading"
      indeterminate
      height="30"
    ></v-progress-linear>
    <v-data-table
      v-if="availableSubproject"
      :key="subprojectListKey"
      v-model="selected"
      :headers="headers"
      :items="availableSubproject"
      :search="search"
      :footer-props="{ itemsPerPageOptions: [5] }"
      :expanded.sync="expanded"
      single-select
      single-expand
      item-key="id"
      class="elevation-0 mt-1"
    >
      <template v-slot:item="{ item, expand }">
        <tr
          :key="item.id"
          :class="selectedStyle(item.name)"
          @click="changeSelected(item)"
          @dblclick="() => openSubproject(selectedSubproject)"
        >
          <td>
            <SubprojectName
              :item="item"
              :renamedSubproject="renamedSubproject"
              :subprojectMetadata="subprojectMetadata"
              :currentSubproject="currentSubproject"
              :availableSubprojectNames="availableSubprojectNames"
              @reset-selected="resetSelected"
            />
          </td>
          <td>{{ item.updatedAt.substring(0, 10) }}</td>
          <td>{{ subprojectMetadata[item.__typename].name }}</td>
          <td>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" icon v-bind="attrs" v-on="on">
                  <v-icon> mdi-dots-vertical </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="
                    () => {
                      renamedSubproject.id = item.id;
                      renamedSubproject.type =
                        subprojectMetadata[item.__typename].name;
                    }
                  "
                >
                  <v-list-item-title>Rename</v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="
                    () => {
                      selectedExpanded = 'delete';
                      expand(true);
                    }
                  "
                >
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="
                    () => {
                      selectedExpanded = 'move';
                      expand(true);
                    }
                  "
                >
                  <v-list-item-title>Copy and Move</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="text-center">
          <SubprojectMove
            v-if="selectedExpanded === 'move'"
            :item="item"
            :subprojectMetadata="subprojectMetadata"
            :listComparisonCharts="listComparisonCharts.items"
            :listProjects="listProjects"
            :currentSubproject="currentSubproject"
            @close-expanded="expanded.pop()"
            @reset-selected="resetSelected"
          />
          <SubprojectDelete
            v-if="selectedExpanded === 'delete'"
            :selectedProject="selectedProject"
            :item="item"
            :subprojectMetadata="subprojectMetadata"
            :listComparisonCharts="listComparisonCharts.items"
            :currentSubproject="currentSubproject"
            @close-expanded="expanded.pop()"
            @update-subprojects="updateSubprojects"
            @reset-selected="resetSelected"
          />
          <SubprojectShare
            v-if="selectedExpanded === 'share'"
            :item="item"
            :subprojectMetadata="subprojectMetadata"
            :currentSubproject="currentSubproject"
            @close-expanded="expanded.pop()"
            @update-subproject="
              (e) => {
                $emit('update-subproject', e);
              }
            "
          />
          <v-divider class="mt-4"></v-divider>
        </td>
      </template>
      <template v-slot:footer>
        <v-btn
          style="position: absolute"
          class="mt-1 ml-1"
          color="primary"
          small
          @click="addSubProjectDialog = true"
          v-if="showCreateButton"
        >
          New SubProject
          <v-icon small> mdi-plus </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="addSubProjectDialog" max-width="500">
      <AddSubProjectDialog
        @close-dialog="addProject()"
        :selectedProject="selectedProject"
        :updateSubprojects="updateSubprojects"
        :addSubProjectDialog="addSubProjectDialog"
        :isSaveAs="false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import AddSubProjectDialog from './AddSubProjectDialog';
import gql from 'graphql-tag';
import {
  listProjects,
  listPhredSubProjects,
  listPhyloTreeSubProjects,
  listComparisonSubProjects,
  listComparisonCharts,
  getPhyloTreeSubProjectByProjectID,
  getComparisonSubProjectByProjectID,
  getPhredSubProjectByProjectID,
} from '@/graphql/queries.js';

import {
  SubprojectDelete,
  SubprojectMove,
  SubprojectShare,
  SubprojectName,
} from '@/components/project';

import { subprojectOpen } from '@/mixins/project';

export default {
  name: 'SubprojectTable',
  components: {
    AddSubProjectDialog,
    SubprojectDelete,
    SubprojectMove,
    SubprojectShare,
    SubprojectName,
  },
  mixins: [subprojectOpen],
  props: {
    currentSubproject: Object,
    selectedProject: String,
    search: String,
    showCreateButton: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    subProjectListKey: 0,
    loading: true,
    availableSubprojectNames: [],
    availableSubproject: [],
    addSubProjectDialog: false,
    selected: [],
    renamedSubproject: {
      id: null,
      type: null,
    },
    expanded: [],
    selectedExpanded: '',
    // data
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Last updated', value: 'updatedAt' },
      { text: 'Type', value: '__typename' },
      { text: 'Edit' },
    ],
    subprojectMetadata: {
      PhredSubProject: { image: 'phage.svg', name: 'Phred' },
      PhyloTreeSubProject: { icon: 'mdi-file-tree', name: 'PhyloTree' },
      Search: { icon: 'mdi-text-box-search' },
      ComparisonSubProject: { icon: 'mdi-ab-testing', name: 'Comparison' },
    },
  }),
  computed: {
    selectedSubproject() {
      if (this.selected.length !== 0) {
        let selected = this.selected[0];
        let subproject = {
          type: this.subprojectMetadata[selected.__typename].name,
          ...selected,
        };
        // add subproject-specific data for comparison
        if (selected.__typename === 'ComparisonSubProject') {
          let charts = this.listComparisonCharts.items.filter(
            (item) => item.subProjectId === selected.id,
          );
          subproject.charts = charts;
        }
        return subproject;
      } else {
        return null;
      }
    },
    editUserTab: {
      get() {
        return this.$store.state.user.editUserTab;
      },
      set(value) {
        this.$store.dispatch('setEditUserTab', value);
      },
    },
  },
  watch: {
    selected() {
      // update selectedSubproject in parent component
      this.$emit(
        'change-selected-subproject',
        this.selected.length !== 0 ? this.selectedSubproject : null,
      );
    },
    selectedProject() {
      this.getAvailableSubproject();
      // reset the view first
      this.resetSelected();

      // update availableSubprojectNames in parent component
      this.$emit(
        'change-available-subproject-names',
        this.availableSubprojectNames,
      );
    },
    editUserTab() {
      // if the tab changes, then we want to reset the view for the subproject table
      this.resetSelected();
    },
  },
  methods: {
    async getAvailableSubproject() {
      this.loading = true;

      if (this.selectedProject.length == 0) {
        return;
      }
      try {
        let arr = [];
        let result = await this.$apollo.query({
          query: getPhyloTreeSubProjectByProjectID,
          variables: {
            projectId: this.selectedProject,
          },
          fetchPolicy: 'network-only',
        });
        const phylotreeSubprojects =
          result.data.getPhyloTreeSubProjectByProjectID.items;
        arr.push(...phylotreeSubprojects);

        result = await this.$apollo.query({
          query: getComparisonSubProjectByProjectID,
          variables: {
            projectId: this.selectedProject,
          },
          fetchPolicy: 'network-only',
        });
        const comparisonSubprojects =
          result.data.getComparisonSubProjectByProjectID.items;
        arr.push(...comparisonSubprojects);

        result = await this.$apollo.query({
          query: getPhredSubProjectByProjectID,
          variables: {
            projectId: this.selectedProject,
          },
          fetchPolicy: 'network-only',
        });
        const phredSubprojects =
          result.data.getPhredSubProjectByProjectID.items;
        arr.push(...phredSubprojects);
        this.availableSubprojectNames = arr.map((item) => item.name);
        this.availableSubproject = arr;
        this.subProjectListKey += 1;
        console.log(this.availableSubproject);
        this.$emit('available-subprojects', arr);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    addProject() {
      //this.$apollo.queries.listProjects.refetch();
      this.addSubProjectDialog = false;
      this.updateSubprojects();
    },
    async updateSubprojects() {
      await this.getAvailableSubproject();
      this.$emit('recently-updated');
    },
    changeSelected(item) {
      this.selected.pop();
      this.selected.push(item);
      this.$emit('entry-selected');
      if (this.selected.length !== 0) {
        let selected = this.selected[0];
        let subproject = {
          type: this.subprojectMetadata[selected.__typename].name,
          ...selected,
        };
        // add subproject-specific data for comparison
        if (selected.__typename === 'ComparisonSubProject') {
          let charts = this.listComparisonCharts.items.filter(
            (item) => item.subProjectId === selected.id,
          );
          subproject.charts = charts;
        }
        this.$emit('pass-subproject-to-parent', subproject);
      } else {
        return null;
      }
    },
    resetSelected() {
      // reset any selected item in the table
      this.selected = [];
      this.expanded = [];
      this.renamedSubproject = { id: null, name: null, type: null };
      this.$emit('reset-selected');
    },
    selectedStyle(name) {
      if (this.selected.length === 0 || name !== this.selected[0].name) {
        return '';
      } else {
        // only highlighted when item is selected
        return 'grey lighten-3';
      }
    },
  },
  created() {
    this.getAvailableSubproject();
  },
  watch: {
    selectedProject() {
      this.getAvailableSubproject();
    },
  },
  apollo: {
    listProjects: {
      query: listProjects,
      error(err) {
        console.log(err);
      },
    },
    listPhredSubProjects: {
      query: listPhredSubProjects,
      error(err) {
        console.log(err);
      },
    },
    listPhyloTreeSubProjects: {
      query: listPhyloTreeSubProjects,
      error(err) {
        console.log(err);
      },
    },
    listComparisonSubProjects: {
      query: listComparisonSubProjects,
      error(err) {
        console.log(err);
      },
    },
    listComparisonCharts: {
      query: listComparisonCharts,
      error(err) {
        console.log(err);
      },
    },
  },
};
</script>
