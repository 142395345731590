`Copyright 2022 Bio-Conversion Databank Foundation

Licensed under the Apache License, Version 2.0(the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License`;

import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  mode: 'history',
});

// setup protected routes
const DEFAULT_TITLE = 'BioDF Portal';
router.beforeEach((to, from, next) => {
  // Use next tick to handle router history correctly
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
    if (document.location.href.includes('#')) {
      document.location.href = String(document.location.href).replace('/#', '');
    }
  });
  store.dispatch('getSession');

  // check the session or local storage to see if user is still authenticated
  var authenticated = false;
  if (localStorage.getItem('rememberMe') == 'true') {
    authenticated = localStorage.getItem('authenticated');
  } else {
    authenticated = sessionStorage.getItem('authenticated');
  }

  if (to.matched.some((record) => record.meta.requiresLogin)) {
    if (!authenticated) {
      next({ name: 'Login' });
    }
  } else if (to.matched.some((record) => record.meta.requiresLogout)) {
    if (!!authenticated) {
      next({ name: 'Dashboard' });
    }
  }
  // Limit access for non-admin users
  // if the listOfPagesWithRestrictedAccess array contains the page we are trying to go to
  // then direct them to under construction page
  if (
    store.state?.limitAccess?.listOfPagesWithRestrictedAccess?.includes(to.name)
  ) {
    if (!store.getters.isAdmin) {
      next({ name: 'UnderConstruction' });
    }
  }

  // if we have user attributes (example email), the 'to' page is not TnC and the user has not accepted the TnC
  // we are using force as a param so that it does not take us back to the TnC page
  if (
    !to.params.force &&
    to.name !== 'TermsAndConditions' &&
    store.state?.user?.userAttributes['email'] &&
    store.state?.user?.userAttributes['custom:accepted-tnc'] !== '1'
  ) {
    next({ name: 'TermsAndConditions' });
    return;
  }

  next();
});

export default router;
