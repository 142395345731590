Copyright 2022 Bio-Conversion Databank Foundation Licensed under the Apache
License, Version 2.0 (the "License"); you may not use this file except in
compliance with the License. You may obtain a copy of the License at
http://www.apache.org/licenses/LICENSE-2.0 Unless required by applicable law or
agreed to in writing, software distributed under the License is distributed on
an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express
or implied. See the License for the specific language governing permissions and
limitations under the License

<template>
  <div>
    <p class="mt-2">Move "{{ item.name }}" to:</p>
    <v-form v-model="isMoveValid">
      <v-autocomplete
        :items="listProjects ? listProjects.items : []"
        item-text="projectName"
        item-value="id"
        v-model="selectedProjectMove"
        label="Select a project"
        class="mx-16"
        :rules="[validateNotEmpty]"
        outlined
        single-line
        dense
      >
      </v-autocomplete>
    </v-form>

    <v-btn
      color="primary"
      outlined
      class="mx-1"
      @click="$emit('close-expanded')"
    >
      Cancel
    </v-btn>
    <v-btn
      color="primary"
      class="mx-1"
      :disabled="!isMoveValid"
      @click="moveSubproject(item.id, subprojectMetadata[item.__typename].name)"
    >
      Move
    </v-btn>
  </div>
</template>

<script>
import {
  updatePhredSubProject,
  updatePhyloTreeSubProject,
  updateComparisonSubProject,
  updateComparisonChart,
} from '@/graphql/mutations.js';
import { validateNotEmpty } from '@/utilities/validationRules';

import { subprojectUpdate } from '@/mixins/project';

export default {
  name: 'SubprojectMove',
  mixins: [subprojectUpdate],
  props: {
    item: Object,
    subprojectMetadata: Object,
    listProjects: Object,
    listComparisonCharts: Array,
    currentSubproject: Object,
  },
  data() {
    return {
      selectedProjectMove: '',
      isMoveValid: false,
    };
  },
  methods: {
    validateNotEmpty,
    updateQuery(type) {
      if (type === 'Phred') {
        return updatePhredSubProject;
      } else if (type === 'PhyloTree') {
        return updatePhyloTreeSubProject;
      } else if (type === 'Comparison') {
        return updateComparisonSubProject;
      }
    },
    moveSubproject(id, type) {
      // reset visual
      this.$emit('reset-selected');
      // move subproject
      this.$apollo
        .mutate({
          mutation: this.updateQuery(type),
          variables: {
            input: {
              id,
              projectId: this.selectedProjectMove,
            },
          },
        })
        .then((data) => {
          if (type === 'Comparison') {
            // continue operation move to
            this.moveCharts(id);
          } else {
            // operation is finished
            this.afterMove(id);
          }
        })
        .catch((err) => {
          console.log(err); // DEBUG
          const notification = {
            type: 'error',
            message: 'Could not move subproject',
          };
          this.$store.dispatch('addNotification', notification);
        });
    },
    moveCharts(id) {
      let charts = this.listComparisonCharts.filter(
        (item) => item.subProjectId === id,
      );
      charts.forEach((item, i) => {
        this.$apollo
          .mutate({
            mutation: updateComparisonChart,
            variables: {
              input: {
                id: item.id,
                projectId: this.selectedProjectMove,
              },
            },
          })
          .then((data) => {
            if (i === charts.length - 1) {
              // only process on the last iteration
              this.afterMove(id);
            }
          })
          .catch((err) => {
            console.log(err); // DEBUG
            const notification = {
              type: 'error',
              message: 'Could not move subproject',
            };
            this.$store.dispatch('addNotification', notification);
          });
      });
    },
    afterMove(id) {
      // dummy data if changed file is opened
      if (this.currentSubproject && this.currentSubproject.id === id) {
        this.updateSubproject('projectId', this.selectedProjectMove);
      }
      const notification = {
        type: 'success',
        message: 'Subproject is successfully moved',
      };
      this.$store.dispatch('addNotification', notification);
    },
  },
};
</script>
