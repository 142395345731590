`Copyright 2022 Bio-Conversion Databank Foundation

Licensed under the Apache License, Version 2.0(the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License`;

export const state = {
  notifications: [],
};

// this variable is incremented so that the notification id is unique
let notificationID = 1;

export const mutations = {
  PUSH_NOTIFICATION(state, value) {
    state.notifications.push({
      ...value,
      id: notificationID++,
    });
  },
  DELETE_NOTIFICATION(state, value) {
    state.notifications = state.notifications.filter(
      (notification) => notification.id !== value.id,
    );
  },
};

export const actions = {
  addNotification({ commit }, value) {
    commit('PUSH_NOTIFICATION', value);
  },
  deleteNotification({ commit }, value) {
    commit('DELETE_NOTIFICATION', value);
  },
};
