`Copyright 2022 Bio-Conversion Databank Foundation

Licensed under the Apache License, Version 2.0(the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License`;

export const validateNotEmpty = (v) => !!v || 'Field cannot be empty';

export function validatePhoneNumber(v) {
  if (!/\+[0-9]{1}\d{10}$/.test(v)) {
    return 'Phone number must be in format +01234567890';
  }
  return true;
}
export function validateEmail(v) {
  if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      String(v).toLowerCase(),
    )
  ) {
    return 'Invalid email format';
  }
  return true;
}
export function validatePassword(v) {
  if (
    !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.\[\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/.test(
      v,
    )
  ) {
    return 'Password must be a minimum of 8 characters, containing 1 uppercase letter, 1 lowercase letter, 1 symbol, and 1 number';
  }
  return true;
}
export function validateSamePassword(passwordOne, passwordTwo) {
  if (!(passwordOne === passwordTwo)) {
    return 'Passwords must match';
  }
  return true;
}

export function validateIsNumber(v) {
  if (!/^\d+$/.test(v)) {
    return 'Must be a number';
  }
  return true;
}

export function validateDelete(v) {
  if (!(v === 'Confirm Delete')) return 'Incorrect phrase';
  return true;
}

export default {
  validateNotEmpty,
  validateEmail,
  validatePassword,
  validateSamePassword,
  validatePhoneNumber,
  validateIsNumber,
  validateDelete,
};
