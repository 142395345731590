Copyright 2022 Bio-Conversion Databank Foundation Licensed under the Apache
License, Version 2.0 (the "License"); you may not use this file except in
compliance with the License. You may obtain a copy of the License at
http://www.apache.org/licenses/LICENSE-2.0 Unless required by applicable law or
agreed to in writing, software distributed under the License is distributed on
an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express
or implied. See the License for the specific language governing permissions and
limitations under the License

<template>
  <v-card>
    <ProjectDialogHeader
      @reset-selected="resetSelected"
      @close-dialog="closeProjectDialog"
      :projectName="projectName"
      :currentSubproject="currentSubproject"
    />
    <v-container class="mt-n6">
      <v-row>
        <v-col class="pa-0">
          <v-card outlined min-height="400" class="rounded-0 mt-1">
            <ProjectDialogSearch
              :listProjects="listProjects"
              :selectedProjectId="selectedProject"
              @change-project="
                (e) => {
                  selectedProject = e;
                  $refs.saveas.validate();
                }
              "
              @search="
                (e) => {
                  search = e;
                }
              "
              @reset-selected="resetSelected"
              @ToggleShow="ToggleShow"
            />

            <SubprojectTable
              v-if="selectedProject"
              :currentSubproject="currentSubproject"
              :selectedProject="selectedProject"
              :search="search"
              :showCreateButton="showCreateButton"
              @entry-selected="toggleOpen"
              @close-dialog="closeProjectDialog"
              @pass-subproject-to-parent="
                (e) => {
                  selectedSubproject = e;
                }
              "
              @change-selected-subproject="
                (e) => {
                  selectedSubproject = e;
                }
              "
              @change-available-subproject-names="
                (e) => {
                  availableSubprojectNames = e;
                }
              "
              ref="subprojectTable"
            />

            <div @click="resetSelected">
              <v-card-actions v-show="selectedTabs === 0">
                <v-row>
                  <v-col sm="12">
                    <v-btn
                      :disabled="!entrySelected"
                      color="primary"
                      @click="() => openSubproject(selectedSubproject)"
                      class="pa-2 button"
                    >
                      Open
                    </v-btn>

                    <v-row v-if="show" no-gutters>
                      <v-col cols="11">
                        <v-text-field
                          label="Enter New Project Name"
                          outlined
                          clearable
                          dense
                          :rules="createRules"
                          v-model="newprojectName"
                          class="mt-15"
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="1">
                        <v-btn
                          @click="createProject"
                          color="primary"
                          :disabled="!isCreateValid"
                          class="mt-15"
                        >
                          Create
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-actions>

              <v-container>
                <v-row v-show="selectedTabs === 1" class="px-2">
                  <v-col cols="9">
                    <v-form v-model="isSaveasValid" ref="saveas">
                      <v-text-field
                        label="Save as"
                        v-model="saveas"
                        class="mr-4"
                        :rules="saveasRules"
                        @keyup.enter="isSaveasValid && saveasSubproject()"
                        outlined
                        single-line
                        dense
                      ></v-text-field>
                    </v-form>
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      class="mb-n3 mt-0 ml-n1"
                      color="primary"
                      @click="saveasSubproject"
                      :disabled="!isSaveasValid"
                    >
                      Save
                    </v-btn>
                  </v-col>

                  <v-row v-if="show">
                    <v-col cols="9">
                      <v-text-field
                        label="Enter New Project Name"
                        outlined
                        clearable
                        dense
                        :rules="createRules"
                        v-model="newprojectName"
                        class="mt-0 ml-3"
                        max-width="50"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        @click="createProject"
                        color="primary"
                        :disabled="!isCreateValid"
                        class="mt-0 ml-3"
                      >
                        Create
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-row>

                <div v-if="show">
                  <v-form v-model="isCreateValid">
                    <v-row
                      v-show="selectedTabs === 2"
                      class="px-2"
                      v-model="isFormValid"
                    >
                      <v-row>
                        <v-text-field
                          label="Enter New Project Name"
                          outlined
                          clearable
                          dense
                          :rules="createRules"
                          v-model="newprojectName"
                          class="mt-6 ml-3"
                          max-width="90"
                        >
                        </v-text-field>
                        <v-btn
                          @click="createProject"
                          color="primary"
                          :disabled="!isCreateValid"
                          class="mt-6 ml-3"
                          large
                        >
                          Create
                        </v-btn>
                      </v-row>
                    </v-row>
                  </v-form>
                </div>
              </v-container>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { listProjects } from '@/graphql/queries.js';
import { subprojectSaveas, subprojectSave } from '@/mixins/project';
import { validateNotEmpty } from '@/utilities/validationRules';
import { createProject } from '@/graphql/mutations.js';
import {
  ProjectDialogHeader,
  ProjectDialogTabs,
  ProjectDialogSearch,
  SubprojectTable,
} from '@/components/project';
import { subprojectOpen } from '@/mixins/project';
export default {
  name: 'ProjectDialog',
  components: {
    ProjectDialogHeader,
    ProjectDialogTabs,
    ProjectDialogSearch,
    SubprojectTable,
  },
  mixins: [subprojectSaveas, subprojectSave, subprojectOpen],
  props: {
    //currentSubproject: Object,
    showCreateButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: false,
      isFormValid: false,
      usedNames: [],
      selectedTabs: 0,
      newprojectName: '',
      // data
      saveas: '',
      selectedProject: '',
      search: '',
      selectedSubproject: null,
      availableSubprojectNames: [],
      usedNames: [],
      // validation
      isSaveasValid: false,
      isCreateValid: false,
      entrySelected: false,
      saveasRules: [
        validateNotEmpty,
        () => !!this.selectedProject || 'Please select a project',
        // BUG: 3rd rule doesn't reevaluate when project changes
        (e) => {
          if (this.availableSubprojectNames.includes(e)) {
            return 'Name is already used';
          }
          return true;
        },
      ],
      createRules: [
        validateNotEmpty,
        (e) => {
          if (this.usedNames.includes(e)) {
            return 'Name is already used';
          }
          return true;
        },
      ],
    };
  },
  methods: {
    ToggleShow() {
      this.show = false;
      if (this.show) {
        this.show = false;
      } else {
        this.show = true;
      }
    },
    resetShow() {
      this.show = false;
    },
    createProject() {
      const projectName = this.newprojectName;
      this.$apollo
        .mutate({
          mutation: createProject,
          variables: {
            input: {
              owner: this.$store.state.user.userAttributes['cognito:username'],
              projectName,
              userId: this.$store.state.user.userAttributes['cognito:username'],
            },
          },
        })
        .then((data) => {
          const notification = {
            type: 'success',
            message: 'Project is successfully created',
          };
          this.refetchProjects();
          this.$store.dispatch('addNotification', notification);
          this.newprojectName = ' ';
        })
        .catch((err) => {
          console.log(err); // DEBUG
          const notification = {
            type: 'error',
            message: 'Something went wrong',
          };
          this.$store.dispatch('addNotification', notification);
        });
    },
    refetchProjects() {
      this.$apollo.queries.listProjects.refetch();
    },
    updateSubprojects() {
      this.$refs.subprojectTable.updateSubprojects();
    },
    resetSelected() {
      this.$refs.subprojectTable.resetSelected();
      this.entrySelected = false;
    },
    resetDialog() {
      this.resetSelected();
    },
    closeProjectDialog() {
      this.$emit('close-dialog');
      this.resetDialog();
    },
    toggleOpen() {
      this.entrySelected = true;
    },
  },
  computed: {
    currentSubproject() {
      return this.$store.state.project.activeSubproject;
    },
    projectName() {
      if (Object.keys(this.currentSubproject).length === 0) {
        return null;
      }
      let arr = this.listProjects.items.filter(
        (item) => item.id === this.currentSubproject.projectId,
      );
      // there will be only one project id that matches
      return arr[0].projectName;
    },
  },
  created() {
    // connect selected project to opened subproject
    if (Object.keys(this.currentSubproject).length !== 0) {
      this.selectedProject = this.currentSubproject.projectId;
    }
  },
  apollo: {
    listProjects: {
      query: listProjects,
      error(err) {
        console.log(err);
      },
    },
  },
};
</script>

<style>
.button {
  float: right;
}
</style>
>
